// Default Browser Font size
$browser-context: 16 !default;
$site-margin: 36 !default;

// z-index: z('modal');
$z-indexes: (modal) !default;

// Media Queries
// @git https://github.com/sass-mq/c
// @see https://sass-mq.github.io/sass-mq

$mq-breakpoints: (
    xs: 320px,
    sm: 420px,
    md: 768px,
    lg: 1024px,
    xl: 1336px // Custom Tweakpoints
    // mobile-landscape:   480px,,,,,,
) !default;

// $mq-show-breakpoints: (xs, sm, md, lg, xl) !default;

$gutter-map: (
    xs: 32px,
    sm: 32px,
    md: 32px,
    lg: 32px,
    xl: 32px,
);

$site-width-map: (
    xs: 300px,
    sm: 420px,
    md: 560px,
    lg: 980px,
    xl: 1336px,
) !default;

// Colors
$color-map: (
    primary: #ff718e,
    info: #71d2ff,
    warn: #e2a600,
    error: #cf0029,
    success: #21b439,
) !default;

$grey-map: (
    bg: #f2f6f8,
    50: #f6f6f6,
    100: #eceff1,
    200: #eceff1,
    300: #bbc3c7,
    400: #bbc3c7,
    500: #879196,
    600: #879196,
    700: #434b4e,
    800: #434b4e,
    900: #24282b,
) !default;

$black: #000;
$white: #fff;

// Iconfont
$font-icon-map: (
    facebook: '\f0c4',
    twitter: '\f0c5',
    googleplus: '\f0c6',
    youtube: '\f0c7',
) !default;

$font-heading: 'Helvetica', sans-serif;
$font-body: 'Helvetica', sans-serif;

$font-map: (
    heading: $font-heading,
    body: $font-body,
) !default;
