.section {
    padding: 64px 0;

    &__inner {
        padding: 2em;

        @include mq(desktop) {
            max-width: 1140px;
            margin: auto;
        }
    }

    .heading {
        margin-bottom: 32px;
    }
    .kicker {}

    .title {
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        color: color(primary);
        -webkit-font-smoothing: antialiased;
    }
}

.hero {
    &__content {
        background: $white;
        margin-bottom: 64px;

        .kicker {
            display: block;
            text-transform: uppercase;
            color: rgb(255, 60, 84);
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            -webkit-font-smoothing: antialiased;
        }
        .title {
            display: block;
            font-weight: 500;
            font-size: 40px;
            line-height: 56px;
            margin-bottom: 16px;
            color: $black;
            -webkit-font-smoothing: antialiased;
        }
        .copy {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 32px;
            color: grey(500);
            -webkit-font-smoothing: antialiased;
        }
        .buttons {
            display: flex;

            a {
                display: inline-block;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &__image {
        margin-left: -32px;
        margin-right: -32px;
    }

    @include mq(tablet) {
        .section__inner {
            display: grid;
            grid-template-columns: 5fr 7fr;
            align-items: center;
            column-gap: 64px;

            > div {

            }
        }
    }
}

.partner {
    background: grey(50);

    &__content {
        
    }

    &-list {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
        row-gap: 16px;
        padding: 0;

        &__item {
            background: rgba(255, 255, 255, .5);
            padding: 0 12px;
            border: 1px solid rgba(grey(100), 1);
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
            }
        }

        @include mq(desktop) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

.problem {
    &__content {
        margin-bottom: 64px;
    }
    .leading  {
        font-size: 22px;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 32px;
        -webkit-font-smoothing: antialiased;
    }
    .copy  {
        font-size: 20px;
        line-height: 32px;
        color: grey(500);
        -webkit-font-smoothing: antialiased;
        margin-bottom: 16px;
    }

    @include mq(desktop) {
        .section__inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 64px;
        }
    }
}

.roadmap {
    background: grey(50);

    &__content {
        
    }


    //  Bulletpoint List
    .list {
        padding-left: 16px;
        font-size: 13px;
        list-style: disc;

        &__item {
            margin-bottom: 16px;
            list-style: none; /* Remove default bullets */

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: color(primary); /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1.2em; /* Also needed for space (tweak if needed) */
            }
        }

        @include mq(desktop) {
            font-size: 16px;
        }
    }

    // Roadmap Items
    &-list {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
        row-gap: 16px;
        padding: 0;

        &__item {
            background: grey(100);
            padding: 12px;
            border: 1px solid rgba(grey(100), 1);
            border-radius: 12px;

            .heading {
                font-weight: 500;
                text-align: center;
            }
            &.is-past {
                opacity: .5;
            }
            &.is-current {
                background: color(primary);
                color: $white;

                .list__item::before {
                    color: $white;
                }
            }
            &.is-future {}

            @include mq(desktop) {
                padding: 32px;

                .heading {
                    // font-size: 20px;
                }
            }
        }

    }
}

.role {
    background: color(primary);
    color: $white;

    .section__inner {

    }

    h2.heading {
        text-align: center;
    }
    .title {
        color: $white;
    }
    &__content {
        margin-bottom: 64px;

        &:first-of-type {
            text-align: center;
        }

        blockquote {
            color: rgb(255, 255, 255);
            font-size: 22px;
            line-height: 34px;
            font-style: italic;
            margin-bottom: 32px;
        }

        .myo {
            text-decoration: none;
            color: $white;
            &__inner {
                background: rgb(230, 55, 75);
                padding: 7px 20px 5px;
                display: inline-block;
                border-radius: 999px;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                line-height: 22px;
                margin-bottom: 8px;
            }

            &__icon {
                margin-right: 8px;
            }
            &__id {
                font-weight: 500;
                margin-right: 8px;
            }
            &__price {
                margin-right: 8px;
            }
            &__percent {
                opacity: .5;
                font-size: 18px;
                line-height: 18px;
            }
            &__info {
                display: block;
            }
        }
    }

    &__footer {
        background: transparent;
        text-align: center;
        padding: 0;

        .leading {
            font-size: 22px;
            line-height: 34px;
            font-weight: 500;
            margin-bottom: 32px;
            -webkit-font-smoothing: antialiased;
        }

        .button {
            color: color(primary);
            cursor: pointer;
            font-size: 13px;
            padding: 10px 20px;
            display: inline-block;
            text-align: center;
            font-weight: 500;
            border-radius: 5px;
            vertical-align: middle;
            background-color: $white;
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
        }
    }

    @include mq(desktop) {
        &__content {
            margin-bottom: 64px;
    
            &:first-of-type {
                text-align: right;
            }

            h3.heading {
                font-size: 22px;
                line-height: 28px;
                font-weight: 500;
                margin-bottom: 16px;
                -webkit-font-smoothing: antialiased;
            }
            ul {
                margin-top: 0;
                margin-bottom: 36px;
            }
            ul li {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 16px;
            }
        }

        .section__inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 128px;

            h2.heading {
                margin-bottom: 128px;
            }
            h2.heading, 
            .role__footer {
                grid-column: 1/-1;
            }
        }

        .myo {
            display: inline-block;
            &__info {
                text-align: center;
            }
        }


    }
}