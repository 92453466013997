@include font-face(
    "BasierCircle",
    "../fonts/BasierCircle-Regular",
    400,
    normal,
    otf woff
);
@include font-face(
    "BasierCircle",
    "../fonts/BasierCircle-SemiBold",
    500,
    normal,
    otf woff
);
@include font-face(
    "BasierCircle",
    "../fonts/BasierCircle-Bold",
    600,
    normal,
    otf woff
);


html {
    height: 100%;
}

body {
    font-family: $font-copy;
    

    &.sticky-footer {
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
    }
}

header,
footer {
    background-color: grey(100);
    padding: 1em 2em;
}

.header {
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: auto;
    }
    .brand {
        display: block;
    }
    .button {
        color: grey(50);
        cursor: pointer;
        font-size: 13px;
        padding: 10px 20px;
        display: inline-block;
        text-align: center;
        font-weight: 500;
        border-radius: 5px;
        vertical-align: middle;
        background-color: grey(900);
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
    }
}

.footer {
    background: grey(900);
    text-align: center;
    padding-top: 48px;
    padding-bottom: 0;

    &__inner {
        max-width: 1140px;
        margin: auto;

        @include mq(desktop) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            
        }
    }

    .logo path {
        fill: $white;
    }
    nav {
        display: block;
        margin: 64px 0;
    }
    nav ul {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    nav ul li {
        margin: 0 12px;
    }
    nav ul li a{
        cursor: pointer;
    }

    &__nav {
        ul li {

        }
        a {
            font-size: 20px;
            line-height: 20px;
            color: $white;
            text-decoration: none;
        }
    }
    &__copyright {
        margin: 0 -32px;
        background: grey(700);
        font-size: 12px;
        color: grey(900);
        padding: 1em;
    }

    @include mq(desktop) {
        padding-top: 0;

        &__social {
            ul {
                justify-content: flex-end !important;
            }
        }
    }

}

main {
    // padding: 2em;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $white;
    overflow-y: auto;

    &__close {
        font-size: 32px;
        position: fixed;
        top: 32px;
        right: 32px;
        background: $white;
        border: none;
        box-shadow: none;
    }
    &__inner {
        box-sizing: border-box;
        margin: auto;
        padding: 32px;
        max-width: 1140px;
    }

    h2 {
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 64px;
    }

    h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin-top: 64px;
        margin-bottom: 32px;
    }

    &.is-active {
        display: block !important;
    }
}