@charset "UTF-8";
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "BasierCircle";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/BasierCircle-Regular.otf") format("opentype"), url("../fonts/BasierCircle-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "BasierCircle";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/BasierCircle-SemiBold.otf") format("opentype"), url("../fonts/BasierCircle-SemiBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "BasierCircle";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/BasierCircle-Bold.otf") format("opentype"), url("../fonts/BasierCircle-Bold.woff") format("woff");
  font-display: swap;
}
html {
  height: 100%;
}

body {
  font-family: "BasierCircle", "Helvetica", sans-serif;
}
body.sticky-footer {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

header,
footer {
  background-color: #eceff1;
  padding: 1em 2em;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}
.header .brand {
  display: block;
}
.header .button {
  color: #f2f6f8;
  cursor: pointer;
  font-size: 13px;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  vertical-align: middle;
  background-color: #24282b;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}

.footer {
  background: #24282b;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 0;
}
.footer__inner {
  max-width: 1140px;
  margin: auto;
}
@media (min-width: 61.25em) {
  .footer__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
}
.footer .logo path {
  fill: #fff;
}
.footer nav {
  display: block;
  margin: 64px 0;
}
.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}
.footer nav ul li {
  margin: 0 12px;
}
.footer nav ul li a {
  cursor: pointer;
}
.footer__nav a {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
}
.footer__copyright {
  margin: 0 -32px;
  background: #434b4e;
  font-size: 12px;
  color: #24282b;
  padding: 1em;
}
@media (min-width: 61.25em) {
  .footer {
    padding-top: 0;
  }
  .footer__social ul {
    justify-content: flex-end !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}
.modal__close {
  font-size: 32px;
  position: fixed;
  top: 32px;
  right: 32px;
  background: #fff;
  border: none;
  box-shadow: none;
}
.modal__inner {
  box-sizing: border-box;
  margin: auto;
  padding: 32px;
  max-width: 1140px;
}
.modal h2 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 64px;
}
.modal h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-top: 64px;
  margin-bottom: 32px;
}
.modal.is-active {
  display: block !important;
}

.section {
  padding: 64px 0;
}
.section__inner {
  padding: 2em;
}
@media (min-width: 61.25em) {
  .section__inner {
    max-width: 1140px;
    margin: auto;
  }
}
.section .heading {
  margin-bottom: 32px;
}
.section .title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: #ff3c54;
  -webkit-font-smoothing: antialiased;
}

.hero__content {
  background: #fff;
  margin-bottom: 64px;
}
.hero__content .kicker {
  display: block;
  text-transform: uppercase;
  color: #ff3c54;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
}
.hero__content .title {
  display: block;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 16px;
  color: #000;
  -webkit-font-smoothing: antialiased;
}
.hero__content .copy {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 32px;
  color: #57586e;
  -webkit-font-smoothing: antialiased;
}
.hero__content .buttons {
  display: flex;
}
.hero__content .buttons a {
  display: inline-block;
  margin-right: 8px;
}
.hero__content .buttons a:last-child {
  margin-right: 0;
}
.hero__image {
  margin-left: -32px;
  margin-right: -32px;
}
@media (min-width: 46.25em) {
  .hero .section__inner {
    display: grid;
    grid-template-columns: 5fr 7fr;
    align-items: center;
    column-gap: 64px;
  }
}

.partner {
  background: #f2f6f8;
}
.partner-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
  padding: 0;
}
.partner-list__item {
  background: rgba(255, 255, 255, 0.5);
  padding: 0 12px;
  border: 1px solid #eceff1;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-list__item img {
  max-width: 100%;
}
@media (min-width: 61.25em) {
  .partner-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.problem__content {
  margin-bottom: 64px;
}
.problem .leading {
  font-size: 22px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 32px;
  -webkit-font-smoothing: antialiased;
}
.problem .copy {
  font-size: 20px;
  line-height: 32px;
  color: #57586e;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 16px;
}
@media (min-width: 61.25em) {
  .problem .section__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 64px;
  }
}

.roadmap {
  background: #f2f6f8;
}
.roadmap .list {
  padding-left: 16px;
  font-size: 13px;
  list-style: disc;
}
.roadmap .list__item {
  margin-bottom: 16px;
  list-style: none;
  /* Remove default bullets */
}
.roadmap .list__item:last-child {
  margin-bottom: 0;
}
.roadmap .list__item::before {
  content: "•";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ff3c54;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1.2em;
  /* Also needed for space (tweak if needed) */
}
@media (min-width: 61.25em) {
  .roadmap .list {
    font-size: 16px;
  }
}
.roadmap-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
  padding: 0;
}
.roadmap-list__item {
  background: #eceff1;
  padding: 12px;
  border: 1px solid #eceff1;
  border-radius: 12px;
}
.roadmap-list__item .heading {
  font-weight: 500;
  text-align: center;
}
.roadmap-list__item.is-past {
  opacity: 0.5;
}
.roadmap-list__item.is-current {
  background: #ff3c54;
  color: #fff;
}
.roadmap-list__item.is-current .list__item::before {
  color: #fff;
}
@media (min-width: 61.25em) {
  .roadmap-list__item {
    padding: 32px;
  }
}

.role {
  background: #ff3c54;
  color: #fff;
}
.role h2.heading {
  text-align: center;
}
.role .title {
  color: #fff;
}
.role__content {
  margin-bottom: 64px;
}
.role__content:first-of-type {
  text-align: center;
}
.role__content blockquote {
  color: white;
  font-size: 22px;
  line-height: 34px;
  font-style: italic;
  margin-bottom: 32px;
}
.role__content .myo {
  text-decoration: none;
  color: #fff;
}
.role__content .myo__inner {
  background: #e6374b;
  padding: 7px 20px 5px;
  display: inline-block;
  border-radius: 999px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 8px;
}
.role__content .myo__icon {
  margin-right: 8px;
}
.role__content .myo__id {
  font-weight: 500;
  margin-right: 8px;
}
.role__content .myo__price {
  margin-right: 8px;
}
.role__content .myo__percent {
  opacity: 0.5;
  font-size: 18px;
  line-height: 18px;
}
.role__content .myo__info {
  display: block;
}
.role__footer {
  background: transparent;
  text-align: center;
  padding: 0;
}
.role__footer .leading {
  font-size: 22px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 32px;
  -webkit-font-smoothing: antialiased;
}
.role__footer .button {
  color: #ff3c54;
  cursor: pointer;
  font-size: 13px;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  vertical-align: middle;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}
@media (min-width: 61.25em) {
  .role__content {
    margin-bottom: 64px;
  }
  .role__content:first-of-type {
    text-align: right;
  }
  .role__content h3.heading {
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    -webkit-font-smoothing: antialiased;
  }
  .role__content ul {
    margin-top: 0;
    margin-bottom: 36px;
  }
  .role__content ul li {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .role .section__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 128px;
  }
  .role .section__inner h2.heading {
    margin-bottom: 128px;
  }
  .role .section__inner h2.heading,
.role .section__inner .role__footer {
    grid-column: 1/-1;
  }
  .role .myo {
    display: inline-block;
  }
  .role .myo__info {
    text-align: center;
  }
}